import { splitProps } from 'solid-js';
import { getSupabaseBrowserClient } from '~/supabase/methods/getSupabaseBrowserClient';
import Button, { type ButtonProps } from '~/ui/components/actions/Button';

export interface OauthButtonProps extends ButtonProps {
  provider: 'facebook' | 'google';
}

export const OauthButton = (props: OauthButtonProps) => {
  const [, buttonProps] = splitProps(props, ['provider']);
  const handleClick = async () => {
    const supabase = getSupabaseBrowserClient();
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('r') ?? '/projects';
    await supabase.auth.signInWithOAuth({
      provider: props.provider,
      options: {
        redirectTo: `${window.location.protocol}://${window.location.host}/${redirect}`,
      },
    });
  };

  return <Button {...buttonProps} onClick={handleClick} />;
};
